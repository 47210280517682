
import { ReactComponent as PhoneIcon } from '../icons/telephone.svg'
import { ReactComponent as MessageIcon } from '../icons/message.svg'
import { useDeviceHook } from '../hooks'

export const Contact = () => {

  const { device, width } = useDeviceHook()

  return (
    <div className="contact">
      <MessageIcon className="message" />
      <PhoneIcon className="telephone" />

      <p className="title-regular no-select">Contacto</p>
      <p className="title-regular roboto">8180178779</p>
      <a
        href='mail:Interhomecontacto@gmail.com'
        style={{
          fontSize: (device === 'desktop' && width >= 1000) ? '1.2rem' : '0.9rem'
        }} 
        className="title-regular"
      >Interhomecontacto@gmail.com</a>
    </div>
  )
}