import { useState } from 'react'
import { Bubble } from './bubble'

export function HoverIconMobile ({ message, children, style, position, rotate, dinamic, Element}) {

  const [visible, setVisible] = useState(false)
  return (
    <div
      className={`hover-alert ${visible ? 'hovering' : ''} ${dinamic ? dinamic : ''}`}
      style={style}
    >
      <Bubble message={message} position={position} rotate={rotate} visible={visible}/>
      <Element 
        onMouseEnter={() => setVisible(true)}
        onMouseLeave={() => setVisible(false)}
        onTouchStart={() => setVisible(true)}
        onTouchEnd={() => setVisible(false)}
      />
    </div>
  )
}