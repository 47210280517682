import React, { memo } from "react"
import {ReactComponent as Contract} from '../icons/Contract.svg'
import { createUrl } from "../lib/createURL"

export function File({name, url, fileName, multiple, files}) {

  const DownloadButton = memo(({name, url, fileName}) => !multiple ? (
    <a href={url} target="_blank" download={fileName} rel="noreferrer">
      {name}
    </a>
  ) : (
    <button
      onClick={(e) => {
        e.preventDefault();

        const urls = files.map(({fileName, extension}) => createUrl(fileName, extension, "IFT"));

        const interval = setInterval((urls) => {
          var url = urls.pop();
        
          var a = document.createElement("a");
          a.setAttribute('href', url);
          a.setAttribute('download', '');
          a.setAttribute('target', '_blank');
          a.click();
        
          if (urls.length === 0) {
            clearInterval(interval);
          }
        }, 500, urls)
      }}
    >
      {name}
    </button>
  ))

  return (
    <div className="file">
      <Contract className="icon banana"/>
      <DownloadButton name={name} url={url} fileName={fileName} />
    </div>
  )
}