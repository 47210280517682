const plans = [
  {
    id: "737476",
    title: 'Inter-Home: Hogar',
    price: "$380.00",
    speedUp: "1Mbps",
    speedDown: "2Mbps",
  },
  {
    id: "737487",
    title: 'Inter-Home: Hogar 2',
    price: "$400.00",
    speedUp: "1Mbps",
    speedDown: "4Mbps",
  },

];

export default plans;