export const Bubble = ({ message, position, rotate, visible }) => (
  <div className={`bubble-message ${rotate ? 'right' : ''} ${visible ? 'show' : 'hide'}`}
    style={position}
  >
    <p style={
      {
        fontSize: '1.125rem',
        fontWeight: '900'
      }
    }>{ message }</p>
  </div>
)