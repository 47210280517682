import React from "react";
import menuOptions from '../menu-options.json'
import { ReactComponent as Laptop } from '../icons/mobile/computer.svg'
import { ReactComponent as Cellphone } from '../icons/mobile/cellphone.svg'
import { ReactComponent as Internet } from '../icons/mobile/internet.svg'
import { ReactComponent as Music } from '../icons/mobile/music.svg'
import { ReactComponent as Person } from '../icons/mobile/person.svg'
import { HoverIconMobile } from "./hoverIconMobile";



export function MobileBottomMenu() {
  const options = menuOptions.options;

  const selectIcon = (iconName) => {
    let selected = null;

    switch(iconName) {
      case 'computer':
        selected = Laptop
      break;
      case 'person':
        selected = Person
      break;
      case 'cellphone':
        selected = Cellphone
      break;
      case 'internet':
        selected = Internet
      break;
      case 'music':
        selected = Music
      break;

      default:
        selected = Laptop;
    }

    return selected
  }

  return (
    <div className="menu">
      {options.map(({message, icon, style}) => 
        <HoverIconMobile
          message={message}
          key={message}
          dinamic={icon}
          Element={selectIcon(icon)}
          rotate={false}
          position={style}
          style={{
            transform: 'unset'
          }}
        >
        </HoverIconMobile>
      )}
    </div>
  )
}