import Header from "../../components/header";
import { MobileBottomMenu } from "../../components/mobileMenu";
import { ReactComponent as HSBC } from '../../icons/HSBC.svg';
import { ReactComponent as OXXO } from '../../icons/Oxxo.svg';

import '../../scss/payment.scss';

export default function Payment() {

  return(
    <div className="main">
      <Header />
      <div className="payment-methods">
        <div className="column">
          <HSBC className="icon hsbc"/>
          <p>Número de tarjeta: <b>4213 1661 4154 4928.</b></p>
          <p>No. De cuenta: <b>6529038213</b></p>
          <p>CLABE: <b>021580065290382137</b></p>
        </div>
        <div className="column">
          <OXXO className="icon" />
          <p>Al depositar en <b>OXXO</b> por favor,</p>
          <p>tome <b>foto</b> de su ticket y mandarlo a nuestro número telefónico por <b>WhatsApp</b>.</p>
          <p><b>81 80 17 87 79</b></p>
        </div>
      </div>
      <MobileBottomMenu />
    </div>
  )
}