import { Link } from "react-router-dom"
import { ReactComponent as Logo } from "../icons/Logo.svg"
import { Menu } from "./Menu"

export default function Header() {
  return (
    <div className="header">
      <Link to='/'>
        <Logo className="logo" />
      </Link>
      <div className="title-slogan">
        <p className="title">Inter Home</p>
        <p className="slogan" >El INTERNET  FACIL</p>
      </div>

      <Menu />
    </div>
  )
}