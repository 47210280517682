import React from 'react';
import ReactDOM from 'react-dom';
import Index from './views/index';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import IFT from './views/ift/index';
import Plans from './views/plans/index';
import Payment from './views/payment/index';

import './scss/main.scss'

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={ <Plans /> } />
        <Route path='/ift' element={ <IFT />} />
        <Route path="/payment" element={ <Payment /> } />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


if(module.hot){
  module.hot.accept()
}