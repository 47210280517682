import { ReactComponent as Contract } from "../icons/Contract.svg"
import { ReactComponent as Calendar } from "../icons/Calendar.svg"
import { ReactComponent as Shopping } from "../icons/shopping.svg"

import '../scss/menu.scss';
import { Link } from "react-router-dom";

export function Menu() {

  const currentPath = window.location.pathname;
  
  const handlePath = (path) => {
    return currentPath === path ? 'nav-bar__item--active' : ''
  }
  

  return (
    <nav className="nav-bar">
      <Link to="/ift" className={`nav-bar__item ${handlePath('/ift')}`}>
        <Contract className="nav-bar__icon" />
        <p className="nav-bar__text">IFT y PROFECO</p>
      </Link>
      <Link to="/"  className={`nav-bar__item ${handlePath("/")}`}>
        <Calendar className="nav-bar__icon" />
        <p className="nav-bar__text">Planes</p>
      </Link>
      <Link to="/payment" className={`nav-bar__item ${handlePath("/payment")}`}>
        <Shopping className="nav-bar__icon" />
        <p className="nav-bar__text">Formas de pago</p>
      </Link>
    </nav>
  )
}