import { Plan } from "../../components/Plan";
import Header from "../../components/header";
import { MobileBottomMenu } from "../../components/mobileMenu";
import plans from "../../lib/plans";

import "../../scss/plans.scss";

export default function Plans() {
  return (
    <div className="main">
      <Header />
      <div className="plans">
        {plans.map((plan, index) => (
          <Plan key={index} {...plan} />
        ))}
      </div>
      <MobileBottomMenu />
    </div>
  )
}
