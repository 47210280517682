import { useEffect } from "react"
import Background from "../../components/background"
import Header from "../../components/header"
import { IftFiles } from "../../components/ift_files"
import { useDeviceHook } from "../../hooks"
import './ift.scss'
import { MobileBottomMenu } from "../../components/mobileMenu"
import { Contact } from "../../components/contact"

export default function IFT() {
  
  const {device, width} =  useDeviceHook()

  useEffect(() => {
    if(device !== 'desktop' || width <= 1000) {
      import('./mobile.scss')
    }
  }, [device, width])
  
  return (
    <div className="main">
      <Header />
      <div className="main-ift-section">
        <div className="title">
          <h1 className="title-extra-bold">Cumplimiento IFT y PROFECO</h1>
        </div>
        <IftFiles />
      </div>
      <Contact />
      <MobileBottomMenu />
    </div>
  )
}