import React from "react"
import { File } from "./file"
import fileObj from '../files.json'
import { createUrl } from "../lib/createURL";

export function IftFiles() {
  const files = fileObj.files;
  const location = fileObj.location;
  
  return (
    <>
      {files.map(({name, fileName, extension, multiple, files}) => (
        <File
          key={name}
          name={name}
          fileName={fileName}
          url={createUrl(fileName, extension, location)}
          multiple={multiple}
          files={files}
        />
      ))}
    </>
  )
}