import { ReactComponent as Barcode } from "../icons/barcode.svg";
import { ReactComponent as Currency } from "../icons/currency.svg";
import { ReactComponent as Down } from "../icons/arrow_downward.svg";
import { ReactComponent as Up } from "../icons/arrow_upward.svg";
import { ReactComponent as Cloud } from "../icons/cloud.svg";
import { ReactComponent as Support } from "../icons/support.svg";
import { ReactComponent as Phone } from "../icons/phone.svg";

export function Plan({title, price, speedUp, speedDown ,id}) {

  return (
    <div className="plan">
      <header className="header">
        <h3 className="title" >{title}</h3>
      </header>
      <main className="plan-body">
        <div className="row id">
          <Barcode className="icon" />
          <p className="id-text">Folio IFT: {id}</p>
        </div>
        <div className="row price">
          <Currency className="icon" />
          <p className="price-text">Mensualidad: {price}</p>
        </div>
        <div className="row speed">
          <Down className="icon" />
          <p className="speed-text">Velocidad de descarga: {speedDown}</p>
        </div>
        <div className="row speed">
          <Up className="icon" />
          <p className="speed-text">Velocidad de subida: {speedUp}</p>
        </div>
        <div className="row support">
          <Support className="icon" />
          <p className="support-text">Soporte técnico</p>
        </div>
        <div className="row cloud">
          <Cloud className="icon" />
          <a className="cloud-text" href="https://tarifas.ift.org.mx/ift_visor/">https://tarifas.ift.org.mx/ift_visor/</a>
        </div>
      </main>
      <footer className="plan-footer">
        <span className="contact-text">
          <Phone className="icon" />
          <p>Contacto</p>
        </span>
        <p className="contact-text">Tel. 81 80 17 87 79</p>
      </footer>
    </div>
  )
}
